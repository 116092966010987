import axios from 'axios';

export function guardar_evento_regimen(sessionId, rut, tipo_regimen, eventoId) {

    const headers = {
        "Content-Type": "application/json",
        //"Authorization": `Bearer ${props.token}`
    };
    const urlIngresarEvento = 'Eventos/ingresarEvento';

    const body_eventos_regimenes = {
        "sessionId": sessionId,
        "eventoId": eventoId,
        "result0": 0,
        "result1": 0,
        "result2": 0,
        "rut": rut
    };

    let resultado = '';

    console.log(`Guardando Evento Regimen ${tipo_regimen}`);
    axios
        .post(urlIngresarEvento, body_eventos_regimenes, { headers: headers })
        .then((response) => {

            if (response.status === 200) {

                switch (tipo_regimen) {
                    case 'A':
                        window.location.href = "/regimena";
                        break;
                    case 'B':
                        window.location.href = "/regimenb";
                        break;
                    case 'Contacto':
                        window.location.href = "/solicitud";
                        break;
                    case 'Apertura':
                        window.location.href = "https://www.afpmodelo.cl/Portal-Afiliado/Operaciones/Ahorro-Previsional-Voluntario/Mi-APV/Abrir-un-APV-Paso1.aspx?acceder&utm_source=QueAPVConviene&utm_medium=referal&utm_campaign=QueAPVConviene&utm_content=BotonAccion";
                        break;
                    default:
                        break;
                }

                
            }
        })
        .catch(e => {
            console.log(e);
        });

    return resultado;
}