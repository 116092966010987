import React from "react";
import ChanchitoA from "../assets/svg/chanchitoa.svg";
import ChanchitoB from "../assets/svg/chanchitob.svg"
import ChanchitoMixto from "../assets/svg/chanchitomixto.svg"
import { aacento, eacento, iacento, oacento } from '../utils/caracteresUTF8';
import { sueldoFormateador } from '../utils/CleanSueldo';

export default function HeaderRecomendacion(props) {

    let texto_regimen = '';
    let alt_imagen = '';
    let url_imagen = '';

    switch (props.recomendacionApv ) {
        case 'A':
            texto_regimen = `En  base a tu renta mensual y el monto del aporte quieres realizar el 15% de bonificaci${oacento}n por parte del Estado es el que m${aacento}s te conviene.`;
            alt_imagen = "regimen A";
            url_imagen = ChanchitoA;
            break;
        case 'B':
            texto_regimen = `En  base a tu renta mensual y el monto del aporte quieres realizar el descuento de tu base tributaria es mayor al aporte del 15% de bonificaci${oacento}n del r${eacento}gimen A.`;
            alt_imagen = "regimen B";
            url_imagen = ChanchitoB;
            break;
        case 'M':
            texto_regimen = `En base a tu renta mensual y el monto a ahorrar  te conviene una mezcla de los reg${iacento}menes A y B, donde obtendr${aacento}s tanto una bonificaci${oacento}n por el ahorro como una rebaja en tu base tributaria.`;
            alt_imagen = "regimen Mixto";
            url_imagen = ChanchitoMixto;
            break;
        default:
            break;
    }

    return (
        <div className="row">
            <div className="col-12 mx-auto text-center header">
                <img
                    src={url_imagen}
                    alt={alt_imagen} />
                <h1>
                    {props.recomendacionApv === 'M' ?
                        `Te recomendamos una combinaci${oacento}n entre el r${eacento}gimen A y el B`
                        :
                        `Te recomendamos el r${eacento}gimen ${props.recomendacionApv}`
                    }
                </h1>
                
                <div className='d-flex justify-content-center'>
                    <p className="texto-regimen-padding">{texto_regimen}</p>
                </div>

                <div className='d-flex justify-content-center'>
                    <p className="texto-explicativo">{props.recomendacionApv === 'A' ? <>
                        Al realizar este aporte el Estado aportará en tu cuenta un 15% adicional. Es decir tu aporte de {sueldoFormateador(props.ahorroMensual)} <span className="naranja">(1)</span> recibir{aacento} una bonificaci{oacento}n de {props.dataRecomendacion.bonificacion} <span className="naranja">(2)</span>. Totalizando un ahorro de {props.dataRecomendacion.total} <span className="naranja">(3)</span>.
                    </> : (props.recomendacionApv === 'B' ? <>
                        En este aporte a tu APV de {sueldoFormateador(props.ahorroMensual)} <span className="naranja">(1)</span> y la rebaja de impuestos de {props.dataRecomendacion.bonificacion} <span className="naranja">(2)</span>, hace que el aporte real de tu bolsillo sea de {props.dataRecomendacion.aporteReal} <span className="naranja">(3)</span>.
                    </> : <>
                        Tienes dos beneficios. En r{eacento}gimen A tu ahorro de {props.dataRecomendacion.aporteMixtoA} <span className="naranja">(1)</span> se sumarán {props.dataRecomendacion.beneficioMixtoA} <span className="naranja">(2)</span> de bonificaci{oacento}n fiscal y al ahorrar {props.dataRecomendacion.aporteMixtoB} <span className="naranja">(3)</span> en el R{eacento}gimen B obtendr{aacento}s una rebaja de impuesto a la renta de {props.dataRecomendacion.beneficioMixtoB} <span className="naranja">(4)</span>, haciendo que el aporte de tu bolsillo sea de {props.dataRecomendacion.aporteFinalMixto} <span className="naranja">(5)</span>.
                    </>)}</p>
                </div>
            </div>
        </div>
    );
}
