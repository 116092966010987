import {cleanDigitos} from "./cleanInputMask";
import axios from "axios";

export const handleSubmitResultado = ({values, changeLoading, regimenData, changeRegimenData}) => {
    changeLoading(true);

    const nombre = regimenData.nombre;
    const rutPrimero = regimenData.rut;
    const rutDv = regimenData.rutDv;
    const rut = rutPrimero + "-" + rutDv;
    const correo = regimenData.correo;
    const celular = regimenData.celular;
    const sueldo = regimenData.sueldoLiquidoConsulta;


    const body = {
        nombre: nombre,
        rut: rut,
        correo: correo,
        celular: celular,
        sueldo: sueldo,
        ahorro: cleanDigitos(values.ahorro)
    };

    const headers = {
        "Content-Type": "application/json"
    };

    const urlIngresarSimulacion = 'ApvSimulacion/ingresarsimulacion';

    axios
        .post(urlIngresarSimulacion, body, { headers: headers })
        .then((response) => {
            let data = response.data;

            if (data.idSimulacion) {
                changeRegimenData(data);
                //console.log(data);
            }

            changeLoading(false);
        })
        .catch(e => {
            console.log(e);
        });
};