export const sueldoFormateador = (value) => {
    const formatterPeso = new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP"
    }).format(value);

    if (formatterPeso.length === 5) {
        let aux_array_pesos = formatterPeso.split('$');
        let aux_pesos = aux_array_pesos[1];

        let final = '$' + aux_pesos[0] + '.' + aux_pesos[1] + aux_pesos[2] + aux_pesos[3];

        return final;
    } else {
        return formatterPeso;
    }


};