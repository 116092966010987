import React, { useEffect } from "react";
import { useState } from "react";
import {aacento, eacento, iacento,oacento,comillaIzquierda, comillaDerecha} from '../utils/caracteresUTF8';
import configuration from "../Config";

const Footer = () => {
    const [datosPrevisionales, setDatosPrevisionales] = useState({
    });

    useEffect(() => {
        const obtenerDatosPrevisionales = async () => {
            console.info("Obteniendo datos previsionales...");
            
            const filejson = await fetch(configuration.API_URL + `ApvSimulacion/obtenercomisiones`);
            const resultado = await filejson.json();
            const valor_uf = parseFloat(resultado['uf'].replace(",", "."));
            const tope_maximo_mensual = 50*valor_uf;
            
            localStorage.setItem('valor50Uf', tope_maximo_mensual);

            setDatosPrevisionales({
                mes: resultado["mes"],
                anio: resultado["anio"],
                capital: resultado["capital"],
                cuprum: resultado["cuprum"],
                habitat: resultado["habitat"],
                modelo: resultado["modelo"],
                planvital: resultado["planvital"],
                provida: resultado["provida"],
                uno: resultado["uno"]
            });
        };
        obtenerDatosPrevisionales().then(r => console.info("Datos previsionales obtenidos..."));
    }, []);

    return (
        <footer className="footer p-1">
            <div className="disclaimer text-white text-justify">
                <p>
                    AFP Modelo no es responsable respecto de la veracidad de los datos que ingrese el usuario a esta calculadora. Para los efectos de este c{aacento}lculo se considera el
                    sueldo una vez efectuados los descuentos de cotizaciones previsionales e impuestos, sin asignaciones de colaci{oacento}n y/o movilizaci{oacento}n. Tablas de montos imponibles como impuestos
                    de segunda categor{iacento}a est{aacento}n actualizadas a {datosPrevisionales.mes} de {datosPrevisionales.anio}, comisi{oacento}n de AFP se calcula utilizando
                    la de AFP Modelo ({datosPrevisionales.modelo}% Mensual). Tanto la bonificaci{oacento}n fiscal, como la disminuci{oacento}n de impuestos a la renta son ilustrativos y podr{iacento}an variar al momento de hacer los aportes de manera efectiva.
                    Inf{oacento}rmese sobre la rentabilidad de su Fondo de Pensiones, las comisiones y la calidad de servicio de las AFP en el sitio web de la Superintendencia de Pensiones: www.spensiones.cl. Estructura de
                    comisiones por dep{oacento}sitos de cotizaciones vigentes a {datosPrevisionales.mes} de {datosPrevisionales.anio} -
                    Capital: {datosPrevisionales.capital}%,
                    Cuprum: {datosPrevisionales.cuprum}%,
                    Habitat: {datosPrevisionales.habitat}%, 
                    Modelo: {datosPrevisionales.modelo}%, 
                    Planvital: {datosPrevisionales.planvital}%, 
                    Provida: {datosPrevisionales.provida}%, 
                    Uno  {datosPrevisionales.uno}%. Para afiliados 
                    dependientes, independientes y voluntarios. Fuente: Superintendencia de Pensiones.
            </p>
        </div>
        </footer>
    );
};

export default Footer;