import React from "react";
import logo from "../assets/img/Logo - Positivo.svg";

const Header = () => (
    <header className="navbar sticky-top navbar-dark bg-dark">
        <div className="align">
            <img src={logo} alt="logo" width="135" height="83.91" />
        </div>
    </header>
);

export default Header;