import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Card } from "react-bootstrap";
import ampolleta from "../assets/svg/ampolleta.svg";
import backgroundMobile from "../assets/svg/mobiles/backgroundMobile.svg";
import APVForm from "../components/ApvForm";
import Preguntas from "../components/PreguntasFrecuentes";
import { v4 as uuidv4 } from 'uuid';
import { aacento, eacento, iacento, oacento, uacento, enhe, interrogacion } from '../utils/caracteresUTF8';
import { guardar_evento_regimen } from '../utils/funcionesEventos';
import configuration from "../Config";

export default function Home() {

    const [token, setToken] = useState('');

    useEffect(() => {
        const obtenerTokenInicial = async () => {
            const response = await axios.get(configuration.API_URL + `Token/GenerateToken`);
            console.info("Generando Token...");
            setToken(response.data.key);
        };

        obtenerTokenInicial().then(r => console.info("Token generado!"));
    }, []);

    const sessionId = uuidv4();
    //Se utiliza rut de AFP Modelo para los eventos.
    const rut_regimen = '76762250-3'

    localStorage.setItem('sessionId', sessionId);
    localStorage.setItem('rut', rut_regimen);

    function conocer_regimen_a(e) {
        e.preventDefault()
        localStorage.setItem('token', token);
        const tipo_regimen = 'A';
        const evento_id = 5;
        guardar_evento_regimen(sessionId, rut_regimen, tipo_regimen, evento_id);
    }

    function conocer_regimen_b(e) {
        e.preventDefault()
        localStorage.setItem('token', token);

        const tipo_regimen = 'B';
        const evento_id = 6;

        guardar_evento_regimen(sessionId, rut_regimen, tipo_regimen, evento_id);
    }

    return (
        <div className="container home">
            <Helmet>
                <title>Ahorro Previsional Voluntario | Simula tu APV | AFP Modelo</title>
                <meta name="description" content={`Aumenta tu sueldo l${iacento}quido, pagando una menor comisi${oacento}n de AFP. Simula tu aumento de sueldo al cambiarte a AFP Modelo.`} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div>
                <section>
                    <div className="row w-100 container-padre calculadora">
                        <div className="col-12 text-center header">
                            <img src={ampolleta} alt="ampolleta" />
                            <div className='container-title title'>
                                <h1>{interrogacion}Por qu{eacento} abrir un APV?</h1>
                                <p>El APV te permite complementar tus ahorros y/o compensar per{iacento}odos no cotizados, para mejorar tu pensi{oacento}n. Dependiendo del r{eacento}gimen tributario que elijas podr{aacento}s recibir un aporte fiscal o rebajar lo ahorrado de tu renta tributable.</p>
                            </div>
                        </div>
                        <div className='background mobile'>
                            <img src={backgroundMobile} alt="background" className={"min-vw-100"}/>
                        </div>
                        <APVForm token={token} />
                    </div>
                </section>
                <section>
                    <div className="row regimenes">
                        <div className="col-12"><h2>Existen dos r{eacento}gimenes de APV</h2></div>
                        <div className="col-sm-12 col-md-6">
                            <Card>
                                <Card.Header>R{eacento}gimen <span className="circle green-circle">A</span></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        En este el Estado te entrega una bonificaci{oacento}n de un 15% de lo que ahorras en el a{enhe}o con un tope de 6 UTM anuales. Por ejemplo, si ahorras $100.000 recibir{aacento}s $15.000 adicionales, por lo que tu cuenta tendra $115.000.
                                    </Card.Text>
                                    <Link to="/regimenA">
                                        <Card.Link onClick={conocer_regimen_a}>Saber M{aacento}s</Card.Link>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <Card>
                                <Card.Header>R{eacento}gimen  <span className="circle orange-circle">B</span></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Con este puedes rebajar tu ahorro de tu renta tributable. Esto lo puedes ver reflejado mes a mes en tu liquidaci{oacento}n de sueldo o una vez al a{enhe}o en la operaci{oacento}n renta, seg{uacento}n realices tu aporte.
                                    </Card.Text>
                                    <Link to="/regimenB">
                                        <Card.Link onClick={conocer_regimen_b}>Saber M{aacento}s</Card.Link>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </section>
                <Preguntas />
            </div>
        </div>
    )
}