import React from "react";
import Table from 'react-bootstrap/Table'
import { eacento, iacento, oacento } from '../utils/caracteresUTF8';


export default function TablaRegimenB(props) {
    const datos = props.data_modal;

    return (
        <Table striped bordered responsive
        >
            <thead>
                <tr>
                    <th></th>
                    <th className="text-right">APV R{eacento}gimen A</th>
                    <th className="text-right font-weight-bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-star-fill star" viewBox="0 0 16 16"><path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                    </svg>APV R{eacento}gimen B</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Sueldo l{iacento}quido:</td>
                    <td className="text-right">{datos.sueldoLiquido}</td>
                    <td className="text-right font-weight-bold">{datos.sueldoLiquido}</td>
                </tr>
                <tr>
                    <td>Ahorro mensual R{eacento}gimen A:</td>
                    <td className="text-right">{datos.aporteApv}</td>
                    <td className="text-right font-weight-bold">-</td>
                </tr>
                <tr>
                    <td>Ahorro mensual R{eacento}gimen B:</td>
                    <td className="text-right">-</td>
                    <td className="text-right font-weight-bold">{datos.aporteApv}</td>
                </tr>
                <tr>
                    <td>Descuentos legales:</td>
                    <td className="text-right">{datos.descuentosLegales}</td>
                    <td className="text-right font-weight-bold">{datos.descuentosLegales}</td>
                </tr>
                <tr>
                    <td>Renta Tributable sin APV:</td>
                    <td className="text-right">{datos.rentaTributableAntesApv}</td>
                    <td className="text-right font-weight-bold">{datos.rentaTributableAntesApv}</td>
                </tr>
                <tr>
                    <td>Tasa de impuestos sin APV :</td>
                    <td className="text-right">{datos.tasaImpuestoSegCatAntesApv}%</td>
                    <td className="text-right font-weight-bold">{datos.tasaImpuestoSegCatAntesApv}%</td>
                </tr>
                <tr>
                    <td>Impuestos sin APV:</td>
                    <td className="text-right">{datos.montoImpuestoSegCatAntesApv}</td>
                    <td className="text-right font-weight-bold">{datos.montoImpuestoSegCatAntesApv}</td>
                </tr>
                <tr>
                    <td>Renta Tributable con APV:</td>
                    <td className="text-right">{datos.rentaTributableDespuesApvA}</td>
                    <td className="text-right font-weight-bold">{datos.rentaTributableDespuesApvB}</td>
                </tr>
                <tr>
                    <td>Tasa de impuestos con APV :</td>
                    <td className="text-right">{datos.tasaImpuestoSegCatDespuesApvA}%</td>
                    <td className="text-right font-weight-bold">{datos.tasaImpuestoSegCatDespuesApvB}%</td>
                </tr>
                <tr>
                    <td>Impuestos con APV:</td>
                    <td className="text-right">{datos.montoImpuestoSegCatDespuesApvA}</td>
                    <td className="text-right font-weight-bold">{datos.montoImpuestoSegCatDespuesApvB}</td>
                </tr>
                <tr>
                    <td className="">Bonificaci{oacento}n fiscal:</td>
                    <td className="text-right green-tabla">{datos.beneficioRegA}</td>
                    <td className="text-right font-weight-bold">NO APLICA</td>
                </tr>
                <tr>
                    <td className="">Descuento tributario:</td>
                    <td className="text-right">NO APLICA</td>
                    <td className="text-right green-tabla font-weight-bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff"
                        className="bi bi-star-fill star" viewBox="0 0 16 16"><path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                    </svg> {datos.beneficioRegB}</td>
                </tr>
                <tr>
                    <td className="">Total Beneficios:</td>
                    <td className="text-right">{datos.beneficioRegA}</td>
                    <td className="text-right font-weight-bold">{datos.beneficioRegB}</td>
                </tr>
                <tr>
                    <td>Nuevo sueldo l{iacento}quido:</td>
                    <td className="text-right">{datos.sueldoLiquidoConApvregA}</td>
                    <td className="text-right font-weight-bold">{datos.sueldoLiquidoConApvregB}</td>
                </tr>
            </tbody>
        </Table>
    );
}