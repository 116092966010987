import React from "react";
import { Helmet } from "react-helmet";
import contacto from "../assets/svg/contacto.svg";
import {Link} from "react-router-dom";
import arrow from "../assets/svg/arrow.svg";
import '../assets/styles/solicitud.css'
import {aacento,eacento,iacento,exclamacion} from "../utils/caracteresUTF8";


export default function solicitud() {

    function redireccion() {
        window.location.href = "https://www.afpmodelo.cl/";
    }


    return (
        <>
            <Helmet>
                <title>Ahorro Previsional Voluntario | Solicitud de Contacto | AFP Modelo</title>
                <meta name="description" content="Aumenta tu sueldo l{iacento}quido, pagando una menor comisi{oacento}n de AFP. Simula tu aumento de sueldo al cambiarte a AFP Modelo." />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="solicitud" >
                <section>
                    <div className="">
                        <div className="row w-100 container-padre dudas">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="container-block">
                                    <img src={contacto} alt="Contacto Ejecutivo" />
                                    <div className="txt">
                                        <div className="container-title">
                                            <h4>{exclamacion}Tu solicitud fue enviada con {eacento}xito!</h4>
                                            <p>Muchas gracias por utilizar la calculadora de Ahorro Previsional Voluntario, un ejecutivo se contactar{aacento} contigo a la brevedad para entregarte toda la asesor{iacento}a necesaria.</p>
                                            <div className="d-flex justify-content-center mb-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-block mt-3"
                                                    id="webmodelo"
                                                    onClick={redireccion}
                                                >
                                                    Visitar sitio AFP Modelo
                                                </button>
                                            </div>
                                            <Link to="/">
                                                <a className='volver'>{' '}<img src={arrow} alt="arrow" />Volver atr{aacento}s</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}