import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import RegimenA from './containers/regimenA';
import RegimenB from './containers/regimenB';
import Solicitud from './containers/solicitud';
import Resultado from './containers/resultado';
import Index from './containers/index';
import './assets/styles/globals.css';
import './assets/styles/index.css';
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
    gtmId: 'GTM-NCT4WV5'
}

TagManager.initialize(tagManagerArgs)

const App = function () {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path='/' exact component ={Index} />
          <Route path='/resultado' component ={Resultado} />
          <Route path='/solicitud' exact component ={Solicitud} />
          <Route path='/regimena' exact component ={RegimenA} />
          <Route path='/regimenb' exact component ={RegimenB} />
        </Switch>
      </Router>

      <Footer />
    </>
  );
}

export default App;
