import React from "react";
import informacion from "../assets/svg/iconoInformacion.svg";

const Alert = (props) => {

    return (
        <div className={`mt-3 alert ${props.className}`} role="alert">
            <div className="row">
                <div className="col-2 alert-svg">
                    <img src={informacion} width="42" height="42"  alt={"warning"}/>
                </div>
                <div className="col-10 avertical-align">
                    <div className="alerta">{props.message}</div>
                </div>
            </div>
        </div>
    )
}

export default Alert;