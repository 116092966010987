import React from "react";
import { Modal, Button } from "react-bootstrap";
import { aacento, eacento, iacento, oacento } from "../utils/caracteresUTF8";
import { sueldoFormateador } from '../utils/CleanSueldo';
import TablaRegimenA from "./TablaRegimenes";
import TablaRegimenB from "./TablaRegimenB";
import TablaMixto from "./TablaMixto";


export default function ResultadosModal(props) {
    //console.log(props);
    
    // Sueldo Liquido, Aporte APV, Recomendacion Regimen
    const sueldoLiquido = props.data.sueldoLiquidoConsulta !== undefined && props.data.sueldoLiquidoConsulta;
    const recomendacionApv = props.data.recomendacionApv !== undefined && props.data.recomendacionApv;
    const aporteApv = props.data.aporteApv !== undefined && props.data.aporteApv;
    
    //Descuentos Legales, Renta Tributable e Imp 2da Categoria
    const aporteAfp = props.data.aporteAfp !== undefined && props.data.aporteAfp;
    const aporteIsapre = props.data.aporteIsapre !== undefined && props.data.aporteIsapre;
    const seguroCesantia = props.data.seguroCesantia !== undefined && props.data.seguroCesantia;
    const comisionAfp = props.data.comisionAfp !== undefined && props.data.comisionAfp;
    const descuentosLegales = Math.trunc(aporteAfp + aporteIsapre + seguroCesantia + comisionAfp);

    const rentaTributable = props.data.sueldoTributario !== undefined && Math.trunc(props.data.sueldoTributario);
    let tasaImpuestoSegCat = props.data.tasaImpuestoSegCat !== undefined && props.data.tasaImpuestoSegCat.toFixed(2);

    if (tasaImpuestoSegCat === '0.14') {
        tasaImpuestoSegCat = '13.5';
    } else if (tasaImpuestoSegCat === '0.30') {
        tasaImpuestoSegCat = '30.4';
    } else {
        tasaImpuestoSegCat = tasaImpuestoSegCat * 100;
    }

    const montoImpuestoSegCat = props.data.montoImpuestoSegCat !== undefined && Math.trunc(props.data.montoImpuestoSegCat);

    
    //Ahorro Mensual Mixto
    const ahorroMensualMixtoA= props.data.mixtoApvA !== undefined && Math.round(props.data.mixtoApvA);
    const ahorroMensualMixtoB = props.data.mixtoApvB !== undefined && Math.round(props.data.mixtoApvB);


    //Renta Tributable Despues APV Solo B y Mixto
    const rentaTributableDespuesApvB = props.data.sueldoTributarioB !== undefined && Math.trunc(props.data.sueldoTributarioB);
    let tasaImpuestoSegCatRegimenB = props.data.tasaImpuestoB !== undefined && props.data.tasaImpuestoB.toFixed(2);


    if (tasaImpuestoSegCatRegimenB === '0.14') {
        tasaImpuestoSegCatRegimenB = '13.5';
    } else if (tasaImpuestoSegCatRegimenB === '0.30') {
        tasaImpuestoSegCatRegimenB = '30.4';
    } else {
        tasaImpuestoSegCatRegimenB = tasaImpuestoSegCatRegimenB * 100;
    }


    const montoImpuestoSegCatRegimenB = props.data.montoImpuestoSegCat !== undefined && Math.trunc(props.data.montoImpuestoSegCat);

    const rentaTributableDespuesApvMixto = props.data.mixtoSueldoTributableApvB !== undefined && Math.trunc(props.data.mixtoSueldoTributableApvB);
    let tasaImpuestoSegCatRegimenMixto = props.data.tasaImpuestoMixtoB !== undefined && props.data.tasaImpuestoMixtoB.toFixed(2);

    if (tasaImpuestoSegCatRegimenMixto === '0.14') {
        tasaImpuestoSegCatRegimenMixto = '13.5';
    } else if (tasaImpuestoSegCatRegimenMixto === '0.30') {
        tasaImpuestoSegCatRegimenMixto = '30.4';
    } else {
        tasaImpuestoSegCatRegimenMixto = tasaImpuestoSegCatRegimenMixto * 100;
    }

    const montoImpuestoSegCatRegimenMixto = props.data.mixtoImpuestoConApvB !== undefined && Math.trunc(props.data.mixtoImpuestoConApvB);

    const impSegCatApvregA = props.data.impSegCatApvregA !== undefined && Math.trunc(props.data.impSegCatApvregA);
    const impSegCatApvregB = props.data.impSegCatApvregB !== undefined && Math.trunc(props.data.impSegCatApvregB);
    const impSegCatApvregMixto = props.data.mixtoImpuestoConApvB !== undefined && Math.trunc(props.data.mixtoImpuestoConApvB);

    
    //Beneficios Regimenes
    const beneficioRegA = props.data.beneficioRegA !== undefined && Math.round(props.data.beneficioRegA);
    
    const beneficioRegB = props.data.beneficioRegB !== undefined && Math.round(props.data.beneficioRegB);
    
    const beneficioRegMixtoA = props.data.mixtoBeneficioApvA !== undefined && Math.round(props.data.mixtoBeneficioApvA);
    const beneficioRegMixtoB = props.data.mixtoBeneficioApvB !== undefined && Math.round(props.data.mixtoBeneficioApvB);
    
    //Total Beneficios Mixto
    const totalBeneficiosRegMixto = props.data.mixtoBeneficioTotal !== undefined && Math.trunc(props.data.mixtoBeneficioTotal);


    //Sueldo Liquido Despues APV
    const sueldoLiquidoConApvregA = props.data.sueldoLiquidoConApvregA !== undefined && Math.round(props.data.sueldoLiquidoConApvregA);
    const sueldoLiquidoConApvregB = props.data.sueldoLiquidoConApvregB !== undefined && Math.round(props.data.sueldoLiquidoConApvregB);
    const sueldoLiquidoConApvregMixto = props.data.mixtoSueldoLiquido !== undefined && Math.round(props.data.mixtoSueldoLiquido);
    
    
    const data_modal = {
        'sueldoLiquido': sueldoFormateador(sueldoLiquido),
        'recomendacionApv': recomendacionApv,
        'aporteApv': sueldoFormateador(aporteApv),
        'ahorroMensualMixtoA': sueldoFormateador(ahorroMensualMixtoA),
        'ahorroMensualMixtoB': sueldoFormateador(ahorroMensualMixtoB),
        'descuentosLegales': sueldoFormateador(descuentosLegales),
        'rentaTributableAntesApv': sueldoFormateador(rentaTributable),
        'tasaImpuestoSegCatAntesApv': tasaImpuestoSegCat,
        'montoImpuestoSegCatAntesApv': sueldoFormateador(montoImpuestoSegCat),
        'rentaTributableDespuesApvA': sueldoFormateador(rentaTributable),
        'tasaImpuestoSegCatDespuesApvA': tasaImpuestoSegCat,
        'montoImpuestoSegCatDespuesApvA': sueldoFormateador(montoImpuestoSegCat),
        'rentaTributableDespuesApvB': sueldoFormateador(rentaTributableDespuesApvB),
        'tasaImpuestoSegCatDespuesApvB': tasaImpuestoSegCatRegimenB,
        'montoImpuestoSegCatDespuesApvB': sueldoFormateador(impSegCatApvregB),
        'rentaTributableDespuesApvMixto': sueldoFormateador(rentaTributableDespuesApvMixto),
        'tasaImpuestoSegCatDespuesApvMixto': tasaImpuestoSegCatRegimenMixto,
        'montoImpuestoSegCatDespuesApvMixto': sueldoFormateador(montoImpuestoSegCatRegimenMixto),
        'beneficioRegA': sueldoFormateador(beneficioRegA),
        'beneficioRegMixtoA': sueldoFormateador(beneficioRegMixtoA),
        'beneficioRegB': sueldoFormateador(beneficioRegB),
        'beneficioRegMixtoB': sueldoFormateador(beneficioRegMixtoB),
        'impSegCatApvregA': sueldoFormateador(impSegCatApvregA),
        'impSegCatApvregB': sueldoFormateador(impSegCatApvregB),
        'impSegCatApvregMixto': sueldoFormateador(impSegCatApvregMixto),
        'sueldoLiquidoConApvregA': sueldoFormateador(sueldoLiquidoConApvregA),
        'sueldoLiquidoConApvregB': sueldoFormateador(sueldoLiquidoConApvregB),
        'sueldoLiquidoConApvregMixto': sueldoFormateador(sueldoLiquidoConApvregMixto),
        'totalBeneficiosRegMixto': sueldoFormateador(totalBeneficiosRegMixto)
    }
    
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Este es el detalle de tu simulaci{oacento}n:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recomendacionApv === 'M' ?
                        <>
                            <TablaMixto
                                data_modal={data_modal}
                            />
                        </> : recomendacionApv === 'A' ?
                            <TablaRegimenA
                            data_modal={data_modal}
                            /> :
                            <TablaRegimenB
                                data_modal={data_modal}
                            />
                        }
                    <p className="disclaimer-modal">Calculos contemplados en modalidad de ahorro mensual a trav{eacento}s de mandato de descuento v{iacento}a empleador con un tope m{aacento}ximo de 50UF de ahorro mensual.*Descuentos legales corresponden a: Ahorro al fondo de pensiones (10%), cotizaci{oacento}n de salud (7%) y comisi{oacento}n AFP Modelo (0,58%).**Renta tributable corresponde a tu renta despu{eacento}s de pagar los descuentos legales.</p>
                </ Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
