import * as Yup from "yup";
import { rutValidador } from "./validationRut";
import { aacento, eacento, iacento, oacento, uacento } from './caracteresUTF8'


export const Step1Schema = Yup.object({
    sueldo: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]+$/, `Ingrese un sueldo l${iacento}quido superior a $100.000.`)
        .test('Sueldo-validacion', `Ingrese un sueldo l${iacento}quido superior a $100.000.`, function (value) {
            return (value >= 100000)
        })
        .required(`Por favor ingrese su sueldo l${iacento}quido.`),
    ahorro: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]+$/, `Ingrese el monto en pesos que desea ahorrar desde $1.000.`)
        .test('Sueldo-validacion', `Ingrese un monto desde $1.000.`, function (value) {
            return (value >= 1000)
        })
        .test('Ahorro-validacion', `El monto del ahorro no puede superar el 80% del sueldo ingresado.`, function (value) {
            const sueldo = parseInt(this.parent.sueldo) * 0.8;
            return (parseInt(value) < sueldo)
        })
        .required('Por favor ingrese el monto que desea ahorrar desde $1.000.'),
});

export const Step2Schema = Yup.object({
    nombre: Yup
        .string()
        .matches(
            /^[A-Za-z \u00F1\u00D1\u00E1\u00E9\u00ED\u00F3\u00FA\u00FC]{3,60}$/,
            `Ingresa tu nombre sin n${uacento}meros ni caracteres.`)
        .required('Ingresa al menos un nombre y apellido.'),
    rut: Yup
        .string()
        .required(`Por favor ingrese un RUT v${aacento}lido con puntos y gui${oacento}n.`)
        .test('Rut-validacion',
            `Por favor ingrese un RUT v${aacento}lido con puntos y gui${oacento}n.`,
            function (value) {
                return rutValidador(value);
            }),
});

export const Step3Schema = Yup.object({
    correo: Yup
        .string()
        .email(`Tu correo debe incluir un "@" y un dominio v${aacento}lido.`)
        .required('Por favor ingrese su correo.'),
    celular: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[9]{1}[0-9]{8}$/, `Ingresa un n${uacento}mero de 9 d${iacento}gitos.`)
        .required(`Por favor ingrese su n${uacento}mero de tel${eacento}fono.`),
    terminosycondiciones: Yup
        .boolean()
        .oneOf([true], `Debe aceptar las condiciones del servicio y la pol${iacento}tica de privacidad.`)
});


export const validationSchemaResultado = Yup.object({
    ahorro: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]+$/, `Ingrese el monto en pesos que desea ahorrar desde $1.000.`)
        .test('Sueldo-validacion', `Ingrese un monto desde $1.000.`, function (value) {
            return (value >= 1000)
        })
        .test('Ahorro-validacion', `El monto del ahorro no puede superar el 80% del sueldo ingresado.`, function (value) {
            const sueldo_liquido = localStorage.getItem('sueldo');
            const sueldo_tope = parseInt(sueldo_liquido) * 0.8;
            return (parseInt(value) <= sueldo_tope)
        })
        .required('Por favor ingrese el monto que desea ahorrar desde $1.000.'),
});