import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Formik, Form } from "formik";
import { Helmet } from "react-helmet";
import { Card, Table } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import ResultadoModal from '../components/ResultadoModal';
import Preguntas from "../components/PreguntasFrecuentes";
import Loader from '../components/Loader';
import HeaderRecomendacion from '../components/HeaderRecomendacion';
import '../assets/styles/resultados.css'
import { dineroMask } from "../utils/inputMask"
import { cleanDigitos } from '../utils/cleanInputMask';
import { eacento, iacento, oacento, uacento, interrogacion } from '../utils/caracteresUTF8';
import { guardar_evento_regimen } from '../utils/funcionesEventos'
import { sueldoFormateador } from '../utils/CleanSueldo';
import mujerSAC from "../assets/svg/mujersac.svg"
import {validationSchemaResultado} from "../utils/validationSchemaWizard";
import {initialValuesResultado} from "../utils/initialValuesFormik";
import {handleSubmitResultado} from "../utils/handleSubmitFormik";
import Alert from "../components/Alert";
import configuration from "../Config";

export default function Resultado() {
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const [regimenData, setRegimenData] = useState({});
    const [valorUf, setValorUf] = useState('');
    
    const handleSubmit = values => {
        handleSubmitResultado({
            values: values,
            changeLoading: setIsLoading,
            changeRegimenData: setRegimenData,
            regimenData: regimenData
        });
    };

    useEffect(() => {
        setValorUf(localStorage.getItem('valor50Uf'));

        const obtenerDatosSimulacion = async () => {
            const id_simulacion = localStorage.getItem('id');
            const response = await axios.get(configuration.API_URL + `ApvSimulacion/obtenerporid?id=${id_simulacion}`);

            let data = response.data;
            
            const sueldo_liquido = Math.round(data.sueldoLiquidoConsulta);
            localStorage.setItem('sueldo', sueldo_liquido);

            if (data.idSimulacion) {
                setRegimenData(data);
            }
        };

        obtenerDatosSimulacion();

    }, []);

    const sueldoLiquido = regimenData.sueldoLiquidoConsulta !== undefined && Math.round(regimenData.sueldoLiquidoConsulta);
    const ahorroMensual = regimenData.aporteApv !== undefined && regimenData.aporteApv;
    const recomendacionApv = regimenData.recomendacionApv !== undefined && regimenData.recomendacionApv;
    const ahorroMensualRegimenA = regimenData.mixtoApvA !== undefined && Math.round(regimenData.mixtoApvA);
    const ahorroMensualRegimenB = regimenData.mixtoApvB !== undefined && Math.round(regimenData.mixtoApvB);
    const mixtoBeneficioApvB = regimenData.mixtoBeneficioApvB !== undefined && Math.round(regimenData.mixtoBeneficioApvB);
    const mixtoBeneficioApvA = regimenData.mixtoBeneficioEfectivoApvA !== undefined && Math.round(regimenData.mixtoBeneficioEfectivoApvA);
    const mixtoBeneficioTotal = regimenData.mixtoBeneficioTotal !== undefined && Math.round(regimenData.mixtoBeneficioTotal);
    const mixtoSueldoLiquido = regimenData.mixtoSueldoLiquido !== undefined && Math.round(regimenData.mixtoSueldoLiquido);
    let beneficio = 0;
    let total = 0;

    const rutPrimero = regimenData.rut;
    const rutDv = regimenData.rutDv;
    const rut = rutPrimero + "-" + rutDv;

    const sessionId = localStorage.getItem('sessionId');

    let dataRecomendacion = {};

    if (recomendacionApv === 'A') {
        beneficio = regimenData.beneficioRegA;
        total = ahorroMensual + beneficio;

        dataRecomendacion = {
            'bonificacion': sueldoFormateador(beneficio),
            'total': sueldoFormateador(total)
        }
    } else if (recomendacionApv === 'B') {
        beneficio = regimenData.beneficioRegB;
        total = Math.round(regimenData.sueldoLiquidoConApvregB);

        dataRecomendacion = {
            'bonificacion': sueldoFormateador(beneficio),
            'aporteReal': sueldoFormateador(ahorroMensual - beneficio)
        }

    } else if (recomendacionApv === 'M') {
        total = Math.round(mixtoSueldoLiquido);
        
        dataRecomendacion = {
            'aporteMixtoA': sueldoFormateador(ahorroMensualRegimenA),
            'aporteMixtoB': sueldoFormateador(ahorroMensualRegimenB),
            'beneficioMixtoA': sueldoFormateador(mixtoBeneficioApvA),
            'beneficioMixtoB': sueldoFormateador(mixtoBeneficioApvB),
            'aporteFinalMixto': sueldoFormateador(ahorroMensual - mixtoBeneficioTotal)
        }
    }
    
    function contactarme() {
        const eventoId = 8;
        const tipo_regimen = 'Contacto';

        guardar_evento_regimen(sessionId, rut, tipo_regimen, eventoId);
    }

    function apertura_afiliado() {
        const eventoId = 10;

        const tipo_regimen = 'Apertura';

        guardar_evento_regimen(sessionId, rut, tipo_regimen, eventoId);

    }

    const dudas_texto =
        `Nuestros ejecutivos pueden asesorarte en l${iacento}nea o v${iacento}a tel${eacento}fonica. Queremos ayudarte a resolver todas tus inquietudes o darte todas las opciones para tu traspaso.`;
    
    return (
        <>
            <Helmet>
                <title>Ahorro Previsional Voluntario | Resultado Simulaci{oacento}n | AFP Modelo</title>
                <meta name="description" content={`Aumenta tu sueldo l${iacento}quido, pagando una menor comisi${oacento}n de AFP. Simula tu aumento de sueldo al cambiarte a AFP Modelo.`} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <section>
                <div className="resultado">
                    <HeaderRecomendacion
                        recomendacionApv={recomendacionApv}
                        ahorroMensual={ahorroMensual}
                        dataRecomendacion={dataRecomendacion}
                    />
                    <div className="row">
                        <div className="col-md-6 offset-md-1 d-flex">
                            <Card>
                                <Card.Body>
                                    <Card.Text>Estos son los datos de tu simulaci{oacento}n:</Card.Text>
                                    {isLoading ?
                                        <Loader />
                                        : <>
                                            <Table responsive className="table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th>{''}</th>
                                                        <th className="text-right">Regimen {recomendacionApv === 'M' ? 'A + B' : recomendacionApv}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {recomendacionApv === 'M'
                                                        ? <>
                                                            <tr>
                                                                <td>Sueldo l{iacento}quido:</td>
                                                                <td className="text-right">{sueldoFormateador(sueldoLiquido)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ahorro mensual R{eacento}gimen A <span className="naranja">(1)</span>:</td>
                                                                <td className="text-right">{sueldoFormateador(ahorroMensualRegimenA)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="green-tabla">Bonificaci{oacento}n fiscal (2):</td>
                                                                <td className="text-right green-tabla">{sueldoFormateador(mixtoBeneficioApvA)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ahorro mensual R{eacento}gimen B <span className="naranja">(3)</span>:</td>
                                                                <td className="text-right">{sueldoFormateador(ahorroMensualRegimenB)}</td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td className="green-tabla">Descuento tributario (4):</td>
                                                                <td className="text-right green-tabla">{sueldoFormateador(mixtoBeneficioApvB)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nuevo sueldo l{iacento}quido:</td>
                                                                <td className="text-right">{sueldoFormateador(total)}</td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td>Total beneficios <span className="naranja">(2 + 4)</span>:</td>
                                                                <td className="text-right">{sueldoFormateador(mixtoBeneficioTotal)}</td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td>El aporte de tu bolsillo es <span className="naranja">(5)</span>:</td>
                                                                <td className="text-right">{sueldoFormateador(ahorroMensual - mixtoBeneficioTotal)}</td>
                                                            </tr>
                                                        </> :
                                                        <>
                                                            <tr>
                                                                <td>Sueldo l{iacento}quido:</td>
                                                                <td className="text-right">{sueldoFormateador(sueldoLiquido)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ahorro mensual <span className="naranja">(1)</span>:</td>
                                                                <td className="text-right">{sueldoFormateador(ahorroMensual)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="green-tabla">{recomendacionApv === 'A' ? `Bonificaci${oacento}n fiscal ` : `Descuento tributario `}(2):</td>
                                                                <td className="text-right green-tabla">{sueldoFormateador(beneficio)}</td>
                                                            </tr>
                                                            {recomendacionApv === 'B'
                                                                ? <>
                                                                    <tr>
                                                                        <td>Aporte final de tu bolsillo <span className="naranja">(3)</span>:</td>
                                                                        <td className="text-right">{sueldoFormateador(ahorroMensual - beneficio)}</td>
                                                                    </tr>
                                                                </>
                                                                : ''}
                                                            <tr>
                                                                {recomendacionApv === 'A' ? <td>Total ahorro <span className="naranja">(3)</span>:</td> : <td>Nuevo sueldo l{iacento}quido:</td>}
                                                                <td className="text-right">{sueldoFormateador(total)}</td>
                                                            </tr>
                                                        </>
                                                    }
                                                    
                                                </tbody>
                                            </Table>
                                            <div className='col-12 text-center'>
                                                <Card.Link className='volver modal-wizard' onClick={handleShow}>Ver detalles de mi simulaci{oacento}n</Card.Link>
                                            </div>
                                            <ResultadoModal
                                                show={modalShow}
                                                onHide={handleClose}
                                                data={regimenData}
                                            />
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 d-flex flex-column calcularApv">
                            <Formik
                                initialValues={initialValuesResultado}
                                onSubmit={handleSubmit}
                                validationSchema={validationSchemaResultado}>
                                {(formik) => (
                                    <>
                                        <div className="row">
                                            <Card>
                                                <Card.Header className="marg-auto"><p>Calcular un nuevo monto de APV:</p></Card.Header>
                                                <Card.Body>
                                                    <Form className='form-inline'>
                                                        <div className='containerForm'>
                                                            <div className="form-group input-wrapper d-block marg-auto ">
                                                                <MaskedInput
                                                                    type="text"
                                                                    mask={dineroMask}
                                                                    className={`form-control form-control-lg ${formik.touched.ahorro ? (formik.errors.ahorro ? "is-invalid" : "is-valid") : ""}`}
                                                                    id="ahorro"
                                                                    name="ahorro"
                                                                    aria-describedby="ahorroAyuda"
                                                                    placeholder="Monto ahorro mensual"
                                                                    {...formik.getFieldProps('ahorro')}
                                                                />
                                                                <small
                                                                    id="ahorroAyuda"
                                                                    className={`form-text ${formik.touched.ahorro && formik.errors.ahorro && 'is-invalid'}`}
                                                                >
                                                                    {formik.touched.ahorro && formik.errors.ahorro}
                                                                </small>
                                                            </div>
                                                            <div className="d-flex justify-content-center marg-auto ">
                                                                <div className="col justify-content-center d-flex">
                                                                    <button
                                                                        type="submit"
                                                                        id="calcular"
                                                                        className="btn btn-success"
                                                                        disabled={!(formik.isValid && formik.dirty)}
                                                                    >
                                                                        Calcular
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="row detalle">
                                            <small>*Renta tributable contempla descuentos legales, ahorro al fondo de pensiones (10%), comisi{oacento}n AFP Modelo (0,58%) y salud (7%). &nbsp;&nbsp;**Bonificaci{oacento}n fiscal de un 15% de tu ahorro voluntario mensual con un tope de 6 UTM anuales.</small>
                                        </div>
                                        {cleanDigitos(formik.values.ahorro) > parseInt(valorUf) && <div className="row detalle">
                                            <Alert
                                                className="alert-secondary"
                                                message={["El tope para recibir beneficios tributarios es de 600 UF anuales. Como tu aporte simulado supera las 50 UF mensuales se presentan los descuentos en impuesto hasta este límite."]} />
                                        </div>
                                        }
                                        
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className='row ctas'>
                        <div className="col-md-4 text-center d-block offset-md-2 container">
                            <p>{interrogacion}Eres afiliado? Haz login para comenzar tu proceso de apertura</p>
                            <div className='d-flex justify-content-center'>
                                <button
                                    type="button"
                                    id="Apertura_Afiliado"
                                    className="btn btn-lg btn-block"
                                    onClick={apertura_afiliado}
                                >Abrir mi APV</button>
                            </div>
                        </div>
                        <div className="col-md-5 text-center d-block container">
                            <p>{interrogacion}No eres afiliado? Nuestros ejecutivos te contactaran para asesorarte en la apertura.</p>
                            <div className='d-flex justify-content-center'>
                                <button type="button" id="Apertura_no_Afiliado" className="btn btn-lg btn-block whiteBtn" onClick={contactarme}>Solicitar contacto</button>
                            </div>
                        </div>
                    </div>
                    <div className="row container-padre dudas">
                        <div className="col-md-2 d-flex desktop">
                            <img src={mujerSAC} alt="Dudas" />
                        </div>
                        <div className="col-sm-12 col-md-6 offset-md-2 d-flex flex-column contenedor">
                            <div className="txtDesktop parrafo2">
                                <div className="container-title">
                                    <h2>{interrogacion}A{uacento}n tienes dudas?</h2>
                                    <p>{dudas_texto}</p>
                                    <button type="button" id="Solicitud_Contacto" className="btn btn-lg btn-block whiteBtn" onClick={contactarme}>Quiero que me contacten</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Preguntas />
        </>
    );
}